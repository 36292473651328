import React,{ createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const LanguageContext = createContext();

export const LanguageContextProvider = (props) => {
    const {i18n} = useTranslation();
    const [language, setLanguage] = useState(null)
    useEffect(() => {
        if (language && language.lang) {
            i18n.changeLanguage(language.lang)
        }
    }, [language])
    
    return (
        <LanguageContext.Provider value={{language, setLanguage}}>
            {props.children}
        </LanguageContext.Provider>
    )
}